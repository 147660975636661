* {
    box-sizing: border-box;

    font-family: "Urbanist", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    bottom: 0.32rem;
    display: flex;
    width: 100%;
    padding: 0 14.5%;
    justify-content: space-between;
    z-index: 99;
    .text {
        font-family: "Urbanist", sans-serif;
        font-weight: 400;
        font-size: 0.18rem;
        color: #ffffff;
        line-height: 0.22rem;
    }
    .link_box {
        @media (max-width: 900px) {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        .text {
            margin-left: 0.36rem;
            @media (max-width: 900px) {
                font-size: 0.22rem;
                line-height: 0.27rem;
                margin-left: 0;
            }
        }
    }
    .regular {
        @media (max-width: 900px) {
            display: none;
        }
    }
}

.page {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 14.5%;
    @media (max-width: 900px) {
        background: url("../../assets/images/bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
        align-items: start;
        padding: 0.88rem 0.44rem 0;
        height: 100%;
        padding-bottom: 1rem;
      
    }

    .page_box {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
        }
        .left {
            position: relative;
            width: 6.4rem;
            height: 6.4rem;
            background-color: transparent;
            border: 0.04rem solid #fff;
            @media (max-width: 900px) {
                width: 6rem;
                height: 6rem;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -0.28rem;
                left: -0.28rem;
                @media (max-width: 900px) {
                    top: -0.38rem;
                    left: -0.38rem;
                }
            }
        }

        .right {
            flex: 1;
            margin-left: 0.88rem;
            @media (max-width: 900px) {
                margin: 0;
                margin-top: 0.4rem;
            }
            .title {
                font-family: "Urbanist", sans-serif;
                font-weight: bold;
                font-size: 0.6rem;
                color: #ffffff;
                line-height: 0.72rem;
                span {
                    font-family: "Urbanist", sans-serif;
                    background-color: #fe8f38;
                }
            }
            .text {
                padding-top: 0.08rem;
                font-family: "Urbanist", sans-serif;
                font-weight: 400;
                font-size: 0.24rem;
                color: #522a0a;
                line-height: 0.36rem;
                @media (max-width: 900px) {
                    font-size: 0.28rem;
                    padding-top: 0;
                }
                p {
                    max-width: 5.68rem;
                    padding-top: 0.24rem;
                    font-family: "Urbanist", sans-serif;
                    @media (max-width: 900px) {
                        max-width: 100%;
                    }
                }
            }
            .app_store {
                max-width: 4.2rem;
                height: 0.96rem;
                margin-top: 0.4rem;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        background: url("../../assets/images/bg1.png") no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }
    .pact-box {
        height: auto;
        display: flex;
        justify-content: center;
        position: relative;
        width: 71%;
        height: 90%;
        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding: 0.36rem 0.48rem;
            width: 100%;
        }
        .left {
            margin-top: 1.1rem;
            position: relative;
            width: 4rem;
            height: 4rem;
            background-color: transparent;
            border: 0.04rem solid #fff;
            @media (max-width: 900px) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: -0.64rem;
                left: -0.64rem;
                @media (max-width: 900px) {
                    top: -0.38rem;
                    left: -0.38rem;
                }
            }
        }

        .right {
            flex: 1;
            margin-left: 0.88rem;
            @media (max-width: 900px) {
                height: 100%;
                width: 100%;
                margin: 0;
            }
            .back {
                font-family: "Urbanist", sans-serif;
                font-weight: 400;
                font-size: 0.2rem;
                color: #7d4920;
                line-height: 0.24rem;
                border-bottom: 0.01rem solid #7d4920;
                @media (max-width: 900px) {
                    font-size: .28rem;
                    line-height: .34rem;
                 }
            }
            .title {
                font-family: "Urbanist", sans-serif;
                font-weight: bold;
                font-size: 0.6rem;
                color: #ffffff;
                line-height: 0.72rem;
                margin-top: 0.2rem;
                text-wrap: nowrap;
                span {
                    font-family: "Urbanist", sans-serif;
                    background-color: #fe8f38;
                }
                @media (max-width: 900px) {
                   margin-top: .36rem;
                   margin-bottom: .4rem;
                }
            }
           
            .pact-cont {
                text-align: left;
                margin-top: 0.29rem;
                height: 80%;
                overflow: scroll;
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }

                @media (max-width: 900px) {
                    height: 82%;
                    margin: 0;
                }

                p,
                li {
                    font-family: "Urbanist", sans-serif;
                    padding-bottom: 0.3rem;
                    font-weight: 400;
                    font-size: 0.24rem;
                    line-height: 0.36rem;
                    color: #522a0a;
                    position: relative;
                    z-index: 9;
                    @media (max-width: 900px) {
                        font-size: 0.30rem;
                        line-height: 0.46rem;
                    }
                }

                h2 {
                    position: relative;
                    font-family: "Urbanist", sans-serif;
                    font-size: 0.36rem;
                    line-height: 0.48rem;
                    font-weight: 700;
                    color: #ffffff;
                    @extend .bold;
                    margin-bottom: 0.14rem;

                    @media (max-width: 900px) {
                        font-size: 0.36rem;
                        line-height: 0.43rem;
                    }
                }

                strong {
                    font-weight: bold;
                }
                .contact {
                    color: #006699;
                }
            }
        }
    }
}
